<template>
  <div
    class="prd-count-container"
  >
    <button
      class="prd-count-btn button-element"
      @click="updateQuantity(-1)"
    >
      <i class="fas fa-minus"></i>
    </button>
    <input
      v-model.number="currentQuantity"
      class="prd-count-number"
      type="text"
      min="0"
      readonly
    />
    <button
      class="prd-count-btn button-element"
      @click="updateQuantity(1)"
    >
      <i class="fas fa-plus"></i>
    </button>
  </div>
</template>

<script>
  export default {
    name: 'ProductQuantityPicker',

    props: {
      quantity: {
        type: Number,
        default: 0
      },
      minQuantity: {
        type: Number,
        default: 1
      }
    },

    data: () => {
      return {
        currentQuantity: 1
      }
    },

    mounted () {
      if (this.quantity > 0) {
        this.currentQuantity = this.quantity
      }
    },

    methods: {
      updateQuantity (quantity) {
        if (this.currentQuantity == '') this.currentQuantity = 0
        if (this.currentQuantity <= this.minQuantity && quantity < 0) return

        this.currentQuantity += quantity
        this.$emit('change', this.currentQuantity)
      },
    }
  }
</script>
